import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import HeroWithPhotoAndHeader from '../../components/HeroWithPhotoAndHeader/heroWithPhotoAndHeader';
import Layout from '../../components/layout';
import SEO from '../../components/SEO/seo';

const InRainbowsAlbumPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout backto="/albums" noheader notopmargin >
    <SEO lang="en" title={'In Rainbows'} keywords={['album', 'album review', 'Artyom Alekseev', 'Артём Алексеев', 'In Rainbows', 'Radiohead']} description="Album In Rainbows by Radiohead. This album became for me the entry point into the wonderful world of Radiohead music.">
        </SEO>
        <HeroWithPhotoAndHeader backto="/albums" desc="Трибьют альбому In Rainbows и записи In Rainbows From the Basement" imageURL={'inrainbows-radiohead.jpeg'} heading="In Rainbows"/>

        <Section >
            <Paragraph>The wind whines through the sealed wooden windows. Foam rubber sticks out through the closed gap in the window. The orange curtains are closed and swinging slowly. It was stuffy because of the included heater, without it it was not like, despite the fact that after a while he knocked out the plugs.</Paragraph>
            <Paragraph>The room was dark. Only the computer monitor was burning, showing a man amusingly turning his head. It was "In Rainbows From the Basement". My first acquaintance with the album.</Paragraph>
            <Paragraph>I was in awe - the wonderful rhythm of the songs that makes you move to the beat. Melancholic, but at the same time cheerful songs that add confidence. With them I want to move - to run, not to sit and wait for a miracle, hoping for the best, but to go and do.</Paragraph>
            <Paragraph>Format is also very important "In Rainbows From the Basement" showed the musicians how they play and how they feel when they play notes. Thom Yorke's magical vocals are revealed even better when you see how he gives himself to the music.</Paragraph>
            <Paragraph>This album became for me the entry point into the wonderful world of Radiohead music.</Paragraph>
        </Section>

    </Layout>
  ); };

export default InRainbowsAlbumPage;
